import moment from 'moment';
import {
    useNavigate,
    useParams
} from 'react-router-dom';
import TableAntd from '../../../components/table';
import {
    useCallback,
    useEffect,
    useState
} from 'react';
import { dateFormat } from '../../../utils';
import {
    DatePicker,
    Segmented,
    Select
} from 'antd';
import SearchInput from '../../../components/SearchInput';
import {
    fetchDeals,
    fetchOriginOptions
} from '../apiService';
import {
    TableOutline,
    TemplateOutline
} from 'heroicons-react';
import FunnelViewDeals from './funnelView';
import SearchEmpty from '../../../components/searchEmpty';
import { useUserRole } from '../../../context/userContext';

const { RangePicker } = DatePicker;
const { Option } = Select;

function TableDeals() {
    const [requestData, setRequestData] = useState();
    const [requestDataTotal, setRequestDataTotal] = useState();
    const [loading, setLoading] = useState(false);
    const [selectedDateRange, setSelectedDateRange] = useState([]);
    const [searchQuery, setSearchQuery] = useState(null);
    const [originOptions, setOriginOptions] = useState([]);
    const [selectedLeadQual, setSelectedLeadQual] = useState(null);
    const [selectedOrigin, setSelectedOrigin] = useState(null);
    const [selectedView, setSelectedView] = useState(localStorage.getItem('dealSelectedView') || '1');
    const navigate = useNavigate();
    const { id } = useParams();
    const userRole = useUserRole();
    const localStorageLabel = localStorage?.getItem('companyName');


    const fetchData = useCallback(async () => {
        try {
            setLoading(true);
            const { data: data2, totalCount } = await fetchDeals(id, selectedOrigin, selectedDateRange, searchQuery, userRole, localStorageLabel);
            setRequestData(data2);
            setRequestDataTotal(totalCount);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    }, [id, localStorageLabel, searchQuery, selectedDateRange, selectedOrigin, userRole]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        const fetchFilters = async () => {
            try {
                const [originResponse] = await Promise.all([
                    fetchOriginOptions(id)
                ]);

                setOriginOptions(originResponse?.data || []);
            } catch (error) {
                console.error("Error fetching filter options:", error);
            }
        };

        fetchFilters();
    }, [id]);
    const columns = [
        {
            title: "Deal Name",
            dataIndex: "dealname",
            key: "dealname",
        },
        {
            title: "DEAL STAGE",
            dataIndex: "stageName",
            key: "stageName",
        },
        {
            title: "Contact Name",
            dataIndex: "firstname",
            key: "firstname",
            render: (text, record) => `${record.firstname || ''} ${record.lastname || ''}`.trim(),
        },
        {
            title: "Deal Amount",
            dataIndex: "amount",
            key: "amount",
            render: (amount) => amount ? `$${parseFloat(amount).toLocaleString()}` : '-',
        },
        {
            title: "Close Date",
            dataIndex: "closedate",
            key: "closedate",
            render: (date) => moment(date).format("DD MMM YYYY"),
        },
        {
            title: "Created At",
            dataIndex: "createdate",
            key: "createdate",
            render: (text, record) => {
                return moment(record?.createdate, 'x').format("DD MMM YYYY");
            },
        },
    ];


    const handleDateRangeChange = (dates) => {
        const newDates = Array.isArray(dates) ? dates : [];
        setSelectedDateRange(newDates);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleOriginChange = (value) => {
        setSelectedOrigin(value);
    };

    const resetFilters = () => {
        setSelectedDateRange([]);
        setSearchQuery('');
        setSelectedLeadQual(null);
        setSelectedOrigin(null);
    };

    const handleRowClick = (record) => {
        navigate(`/partnerships/vendorDetail/${id}/ViewContact/${record.contactEmail}`, {
            state: {
                labelText: "Deals"
            },
        });
    };

    const SegmentedValue = [
        {
            value: '1',
            label: "Table View",
            icon: <TableOutline width={20} />,
        },
        {
            value: '2',
            label: "Funnel View",
            icon: <TemplateOutline width={20} />,
        },
    ];

    const handleSegmentedChange = async (value) => {
        if (loading) return;

        setSelectedView(value);
        localStorage.setItem('dealSelectedView', value);

        await fetchData();
    };

    return (
        <div className='table-cursor projectRequestpage'>
            <SearchInput
                placeholder="Search by deal name"
                onChange={handleSearchChange}
                className="mb-4"
            />
            <div className='flex gap-2 justify-between  items-center mb-4'>
                <div>
                    <Segmented
                        options={SegmentedValue}
                        onChange={handleSegmentedChange}
                        value={selectedView}
                    />
                </div>
                <div className=' flex flex-row gap-3 items-center event-page'>
                    <div className="hidden md:flex">
                        {(selectedLeadQual || selectedOrigin || selectedDateRange.length > 0) && (
                            <div className="cursor-pointer purple-saas22" onClick={resetFilters}>
                                Clear Filters
                            </div>
                        )}
                    </div>
                    <RangePicker
                        value={selectedDateRange}
                        format={dateFormat}
                        className="range-picker mb-0"
                        onChange={handleDateRangeChange}
                        allowClear={selectedDateRange.length > 0}
                    />
                    <Select
                        showSearch
                        placeholder="Origin"
                        optionFilterProp="children"
                        onChange={handleOriginChange}
                        className='mb-0 custom-select'
                        value={selectedOrigin}
                    >
                        <Option value="Shared by SurveySparrow">Shared by SurveySparrow</Option>
                        <Option value="Logged by Partner">Logged by Partner</Option>
                    </Select>
                </div>
            </div>
            {selectedView === '1' ?
                <TableAntd
                    dataSource={requestData && requestData?.map(item => ({
                        ...item.properties,
                        key: item.id
                    }))}
                    columns={columns}
                    locale={{ emptyText: <SearchEmpty filteredProduct={requestDataTotal} /> }}
                    onRow={(record) => ({
                        onClick: () => handleRowClick(record),
                    })}
                />
                :
                selectedView === '2' ?
                    <FunnelViewDeals
                        searchQuery={searchQuery}
                        selectedOrigin={selectedOrigin}
                        selectedDateRange={selectedDateRange}
                        loading={loading}
                        setLoading={setLoading}
                        requestDataTotal={requestDataTotal}
                    />
                    :
                    <TableAntd
                        dataSource={requestData && requestData?.map(item => ({
                            ...item.properties,
                            key: item.id
                        }))}
                        columns={columns}
                        locale={{ emptyText: <SearchEmpty filteredProduct={requestDataTotal} /> }}
                        onRow={(record) => ({
                            onClick: () => handleRowClick(record),
                        })}
                    />
            }

        </div>
    );
}

export default TableDeals;